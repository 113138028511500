<template>
  <div>
    <v-tabs
      @change="currentActiveTab"
      background-color="transparent"
      color="indigo darken-4"
      grow
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.title"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>
    <CRow>
      <CCol sm="12">
        <div class="bg-white p-3">
          <ActiveTabView v-if="activeTab" :activeTab="activeTab"/>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'EntityTabsContent',
  data () {
    return {
      activeTab: null
    }
  },
  components: {
    ActiveTabView: () => import('@/core/views/tabsView/ActiveTabView')
  },
  computed: {
    tabs () {
      let tabs = []
      if (this.currentEntity) {
        tabs = this.currentEntity.tabs
      }
      return tabs
    },
    ...mapState({
      is_loading: state => state.is_loading,
      currentEntity: state => state.currentEntity
    })
  },
  methods: {
    currentActiveTab (activeTab = 0) {
      this.activeTab = this.tabs[activeTab]
    },

    loadCurrentEntity (newEntity, oldEntity) {
      if (newEntity !== oldEntity) {
        this.loadEntity({ entity: newEntity }).then((resp)=>{
          console.log(resp)
        }).catch((error)=>{
          console.log(error)
        })
      }
    },
    ...mapActions([
      'loadEntity'
    ])
  },
  created () {
    if (this.$route.query?.pageNo)
      this.$router.push({ query: {} })
  },
  watch: {
    '$route.params.entity': {
      deep: true,
      immediate: true,
      handler: 'loadCurrentEntity'
    },
    'tabs': {
      deep: true,
      immediate: true,
      handler (tabs) {
        if (tabs && tabs.length > 0) {
          this.currentActiveTab(0)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
